import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { LocalForm, actions } from 'commons/js/components/form'
import { Flex, Box, Copy } from '@elparking/components'
import { FormattedMessage } from 'react-intl'
import { TRADENAME, LOGGABLE_COUNTRIES } from 'commons/js/constants'
import LoaderButton from 'commons/js/ui/LoaderButton'

import FieldGroup from 'commons/js/ui/FieldGroup'
import CountryField from 'commons/js/ui/CountryField'
import TermsLink from 'commons/js/ui/TermsLink'
import findCountryBy from './findCountryBy'
import defaultCountry from 'commons/js/country'

const initialState = {
    countryIso3: defaultCountry.toUpperCase(),
}

class CountryForm extends Component {
    static propTypes = {
        loading: PropTypes.bool,
        onSubmit: PropTypes.func,
    }

    static defaultProps = {
        onSubmit: () => {},
    }

    state = {
        acceptedCountries: LOGGABLE_COUNTRIES,
        defaultCountry: findCountryBy(LOGGABLE_COUNTRIES, { iso3: defaultCountry.toUpperCase() }),
    }

    componentDidUpdate (_, prevState) {
        if (prevState.defaultCountry !== this.state.defaultCountry) {
            this.formDispatch(actions.change('registration.country', this.state.defaultCountry.iso3))
        }
    }

    attachDispatch = this.attachDispatch.bind(this)

    attachDispatch (dispatch) {
        this.formDispatch = dispatch
    }

    onSubmit = this.onSubmit.bind(this)

    onSubmit (values) {
        const country = findCountryBy(this.state.acceptedCountries, { iso3: values.countryIso3 })

        this.props.onSubmit({
            ...values,
            country: country.iso2,
        })
    }

    onChange = this.onChange.bind(this)

    onChange ({ countryIso3 }) {
        this.setState(() => ({ selectedCountry: countryIso3 }))
    }

    render () {
        const { loading } = this.props
        const { acceptedCountries, defaultCountry } = this.state

        return (
            <LocalForm
              model='registration'
              onChange={this.onChange}
              onSubmit={this.onSubmit}
              initialState={initialState}
              getDispatch={this.attachDispatch}
            >
                <Flex flexWrap='wrap'>
                    <Box w='100%' flex='1 0 100%' mb='24px' mt='8px'>
                        <Copy padding='0' size='xxLarge' align='center'>
                            <FormattedMessage
                              defaultMessage='¿Dónde usarás {tradename}?'
                              values={{ tradename: TRADENAME }}
                            />
                        </Copy>
                    </Box>
                    <Box w='100%' flex='1 0 100%' mb='48px'>
                        <Copy padding='0' size='medium' fontWeight='regular' align='center'>
                            <FormattedMessage
                              defaultMessage='De esta forma, sabremos qué moneda tenemos que utilizar para realizar tus pagos.'
                            />
                        </Copy>
                    </Box>
                    <Flex w='100%' justifyContent='center' mb='48px'>
                        <img src='/images/login/countries.svg' alt='countries' />
                    </Flex>
                    <FieldGroup disabled={loading}>
                        <Box w='100%' flex='1 0 100%' mb='48px'>
                            <CountryField
                              countries={acceptedCountries}
                              defaultCountry={defaultCountry}
                            />
                        </Box>
                    </FieldGroup>
                    <Box w='100%' flex='1 0 100%' mb='16px'>
                        <Copy padding='0' fontWeight='regular' size='xxSmall' align='center'>
                            <TermsLink
                              countries={this.state.acceptedCountries}
                              selectedCountry={this.state.selectedCountry}
                            >
                                <FormattedMessage
                                  defaultMessage='Al registrarte aceptas los términos y condiciones de uso de {tradename}'
                                  values={{ tradename: TRADENAME }}
                                />
                            </TermsLink>
                        </Copy>
                    </Box>
                    <LoaderButton dataTest='submit-button' formNoValidate loading={loading} model='registration' width='100%'>
                        <FormattedMessage
                          defaultMessage='Confirmar país'
                        />
                    </LoaderButton>
                </Flex>
            </LocalForm>
        )
    }
}

export default CountryForm
