const bookingProcessSelectAnonymousAdditionalServices = 'bookingProcessSelectAnonymousAdditionalServices'
const parkingLandingChangeType = 'parkingLandingChangeType'
const parkingLandingChangePlace = 'parkingLandingChangePlace'
const parkingLandingStartDate = 'parkingLandingStartDate'
const parkingLandingEndDate = 'parkingLandingEndDate'
const parkingLandingSearch = 'parkingLandingSearch'
const parkingSearchChangeDate = 'parkingSearchChangeDate'
const parkingSearchChangePlace = 'parkingSearchChangePlace'
const parkingSearchItemSelect = 'parkingSearchItemSelect'
const parkingSearchCardSelect = 'parkingSearchCardSelect'
const parkingDetailChangeDate = 'parkingDetailChangeDate'
const parkingDetailChangePlace = 'parkingDetailChangePlace'
const parkingDetailSearch = 'parkingDetailSearch'
const bookingProcessChangeDates = 'bookingProcessChangeDates'
const bookingProcessSelectVehicle = 'bookingProcessSelectVehicle'
const bookingProcessUpdateUserData = 'bookingProcessUpdateUserData'
const bookingProcessUpdateBookingServices = 'bookingProcessUpdateBookingServices'
const bookingProcessPay = 'bookingProcessPay'
const bookingProcessConfirm = 'bookingProcessConfirm'
const seasonTicketProcessChangeDate = 'seasonTicketProcessChangeDate'
const seasonTicketProcessSelectVehicle = 'seasonTicketProcessSelectVehicle'
const seasonTicketProcessPay = 'seasonTicketProcessPay'
const walletAddCreditCard = 'walletAddCreditCard'
const walletCreateDeposit = 'walletCreateDeposit'
const bookingProcessStep1 = 'bookingProcessStep1'
const bookingProcessAnonymousStep2 = 'bookingProcessAnonymousStep2'
const bookingProcessAnonymousStep3 = 'bookingProcessAnonymousStep3'
const bookingProcessStep2 = 'bookingProcessStep2'
const bookingProcessStep3 = 'bookingProcessStep3'
const bookingProcessChangeCreditCard = 'bookingProcessChangeCreditCard'
const bookingProcessChangeWallet = 'bookingProcessChangeWallet'
const bookingProcessSelectAdditionalServices = 'bookingProcessSelectAdditionalServices'
const bookingProcessLogin = 'bookingProcessLogin'
const bookingProcessLogout = 'bookingProcessLogOut'
const bookingProcessShowBookingDetail = 'bookingProcessShowBookingDetail'
const bookingProcessInputPromoCode = 'bookingProcessInputPromoCode'
const bookingProcessInputVehicleSelect = 'bookingProcessInputVehicleSelect'
const bookingProcessAnonymousInputFirstName = 'bookingProcessAnonymousInputFirstName'
const bookingProcessAnonymousInputLastName = 'bookingProcessAnonymousInputLastName'
const bookingProcessAnonymousInputPhone = 'bookingProcessAnonymousInputPhone'
const bookingProcessAnonymousInputEmail = 'bookingProcessAnonymousInputEmail'
const bookingProcessAnonymousInputVehicleNew = 'bookingProcessAnonymousInputVehicleNew'
const bookingProcessAnonymousInputCreditCardNumber = 'bookingProcessAnonymousInputCreditCardNumber'
const bookingProcessAnonymousInputCreditCardExpiry = 'bookingProcessAnonymousInputCreditCardExpiry'
const bookingProcessAnonymousInputCreditCardCVC = 'bookingProcessAnonymousInputCreditCardCVC'
const bookingProcessAnonymousBack3 = 'bookingProcessAnonymousBack3'
const bookingProcessAnonymousBack2 = 'bookingProcessAnonymousBack2'
const bookingProcessInputFirstName = 'bookingProcessInputFirstName'
const bookingProcessInputLastName = 'bookingProcessInputLastName'
const bookingProcessInputPhone = 'bookingProcessInputPhone'
const bookingProcessInputEmail = 'bookingProcessInputEmail'
const bookingProcessInputVehicleNew = 'bookingProcessInputVehicleNew'
const bookingProcessInputCreditCardNumber = 'bookingProcessInputCreditCardNumber'
const bookingProcessInputCreditCardExpiry = 'bookingProcessInputCreditCardExpiry'
const bookingProcessInputCreditCardCVC = 'bookingProcessInputCreditCardCVC'
const bookingProcessBack3 = 'bookingProcessBack3'
const bookingProcessBack2 = 'bookingProcessBack2'
const bookingProcessAnonymousPay = 'bookingProcessAnonymousPay'
const bookingProcessAnonymousConfirm = 'bookingProcessAnonymousConfirm'
const bookingProcessConfirmation = 'bookingProcessConfirmation'
const bookingProcessAnonymousConfirmation = 'bookingProcessAnonymousConfirmation'
const loginSuccess = 'loginSuccess'
const serTicketProcessPay = 'serTicketProcessPay'
const parkingPassProcessPay = 'parkingPassProcessPay'
const signup = 'signup'
const vehicleInspectionLandingChangeVehicleType = 'vehicleInspectionLandingChangeVehicleType'
const vehicleInspectionLandingChangePlace = 'vehicleInspectionLandingChangePlace'
const vehicleInspectionLandingSearch = 'vehicleInspectionLandingSearch'
const vehicleInspectionSearchChangeVehicleType = 'vehicleInspectionSearchChangeVehicleType'
const vehicleInspectionSearchChangePlace = 'vehicleInspectionSearchChangePlace'
const chargingPointSearchChangePlace = 'chargingPointSearchChangePlace'
const vehicleInspectionSearchSelectStation = 'vehicleInspectionSearchSelectStation'
const vehicleInspectionStationChangeVehicleType = 'vehicleInspectionStationChangeVehicleType'
const vehicleInspectionStationSelectProduct = 'vehicleInspectionStationSelectProduct'
const vehicleInspectionStationCheckOut = 'vehicleInspectionStationCheckOut'
const vehicleInspectionProcessStep1 = 'vehicleInspectionProcessStep1'
const vehicleInspectionProcessStep2 = 'vehicleInspectionProcessStep2'
const vehicleInspectionProcessStep3 = 'vehicleInspectionProcessStep3'
const vehicleInspectionProcessPay = 'vehicleInspectionProcessPay'
const vehicleInspectionProcessConfirmation = 'vehicleInspectionProcessConfirmation'
const electricChangeSearch = 'electricChangeSearch'
const electricChangeSearchChangePlace = 'electricChangeSearchChangePlace'
const chargingPointSearchItemSelect = 'chargingPointSearchItemSelect'
const sendContactForm = 'sendContactForm'
const viatShopInputPromoCode = 'viatShopInputPromoCode'
const workshopSelectProduct = 'workshopSelectProduct'
const workshopSelectWorkshop = 'workshopSelectWorkshop'
const workshopSelectVehicle = 'workshopSelectVehicle'
const workshopSelectionComplete = 'workshopSelectionComplete'

const workshopBookingProcessStep1 = 'workshopBookingProcessStep1'
const workshopBookingProcessStep2 = 'workshopBookingProcessStep2'
const workshopBookingProcessStep3 = 'workshopBookingProcessStep3'

export {
    parkingLandingChangeType,
    parkingLandingChangePlace,
    parkingLandingStartDate,
    parkingLandingEndDate,
    parkingLandingSearch,
    parkingSearchChangeDate,
    parkingSearchChangePlace,
    parkingSearchItemSelect,
    parkingSearchCardSelect,
    parkingDetailChangeDate,
    parkingDetailChangePlace,
    parkingDetailSearch,
    bookingProcessChangeDates,
    bookingProcessSelectVehicle,
    bookingProcessUpdateUserData,
    bookingProcessUpdateBookingServices,
    bookingProcessPay,
    bookingProcessConfirm,
    seasonTicketProcessChangeDate,
    seasonTicketProcessSelectVehicle,
    seasonTicketProcessPay,
    walletAddCreditCard,
    walletCreateDeposit,
    bookingProcessStep1,
    bookingProcessAnonymousStep2,
    bookingProcessAnonymousStep3,
    bookingProcessSelectAnonymousAdditionalServices,
    bookingProcessStep2,
    bookingProcessStep3,
    bookingProcessChangeCreditCard,
    bookingProcessChangeWallet,
    bookingProcessSelectAdditionalServices,
    bookingProcessLogin,
    bookingProcessLogout,
    bookingProcessShowBookingDetail,
    bookingProcessInputPromoCode,
    bookingProcessInputVehicleSelect,
    bookingProcessAnonymousInputFirstName,
    bookingProcessAnonymousInputLastName,
    bookingProcessAnonymousInputPhone,
    bookingProcessAnonymousInputEmail,
    bookingProcessAnonymousInputVehicleNew,
    bookingProcessAnonymousInputCreditCardNumber,
    bookingProcessAnonymousInputCreditCardExpiry,
    bookingProcessAnonymousInputCreditCardCVC,
    bookingProcessAnonymousBack3,
    bookingProcessAnonymousBack2,
    bookingProcessInputFirstName,
    bookingProcessInputLastName,
    bookingProcessInputPhone,
    bookingProcessInputEmail,
    bookingProcessInputVehicleNew,
    bookingProcessInputCreditCardNumber,
    bookingProcessInputCreditCardExpiry,
    bookingProcessInputCreditCardCVC,
    bookingProcessBack3,
    bookingProcessBack2,
    bookingProcessAnonymousPay,
    bookingProcessAnonymousConfirm,
    bookingProcessConfirmation,
    bookingProcessAnonymousConfirmation,
    loginSuccess,
    serTicketProcessPay,
    parkingPassProcessPay,
    signup,
    vehicleInspectionLandingChangeVehicleType,
    vehicleInspectionLandingChangePlace,
    vehicleInspectionLandingSearch,
    vehicleInspectionSearchChangeVehicleType,
    vehicleInspectionSearchChangePlace,
    chargingPointSearchChangePlace,
    vehicleInspectionSearchSelectStation,
    vehicleInspectionStationChangeVehicleType,
    vehicleInspectionStationSelectProduct,
    vehicleInspectionStationCheckOut,
    vehicleInspectionProcessStep1,
    vehicleInspectionProcessStep2,
    vehicleInspectionProcessStep3,
    vehicleInspectionProcessPay,
    vehicleInspectionProcessConfirmation,
    electricChangeSearch,
    electricChangeSearchChangePlace,
    chargingPointSearchItemSelect,
    sendContactForm,
    viatShopInputPromoCode,
    workshopSelectProduct,
    workshopSelectWorkshop,
    workshopSelectVehicle,
    workshopSelectionComplete,
    workshopBookingProcessStep1,
    workshopBookingProcessStep2,
    workshopBookingProcessStep3,
}
