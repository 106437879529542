/* istanbul ignore file */
import React from 'react'
import PropTypes from 'prop-types'
import { Control, Errors, actionTypes } from 'commons/js/components/form'
import { Checkbox } from '@elparking/components'
import { Error as FieldError, Wrapper as ErrorsWrapper } from '../Error'

const mapProps = {
    valid: ({ fieldValue }) => fieldValue.valid,
    changed: ({ fieldValue }) => fieldValue.touched,
    forceFocus: ({ fieldValue, ...props }, other) => {
        const { intents } = fieldValue
        if (!Array.isArray(intents) || !intents.length) {
            return false
        }
        return intents.some((intent) => intent.type === actionTypes.FOCUS)
    },
}

const ControlField = React.forwardRef(({
    model, errorMessages, showErrorOptions, errorColor, ...restProps
}, ref) => {
    return (
        <div>
            <Control.checkbox
              ref={ref}
              component={Checkbox}
              model={model}
              mapProps={mapProps}
              errorColor={errorColor}
              {...restProps}
               />
            { errorMessages &&
                <Errors
                  wrapper={ErrorsWrapper}
                  component={({children}) => <FieldError errorColor={errorColor}>{children}</FieldError>}
                  model={model}
                  messages={errorMessages}
                  show={showErrorOptions} /> }
        </div>
    )
})

ControlField.propTypes = {
    model: PropTypes.string.isRequired,
    validators: PropTypes.object,
    showErrorOptions: PropTypes.object,
    errorMessages: PropTypes.object,
    errorColor: PropTypes.string,
    focus: PropTypes.string,
}

ControlField.defaultProps = {
    errorColor: 'red',
    showErrorOptions: {touched: true},
}
export default ControlField
