import React from 'react'
import PropTypes from 'prop-types'
import { Flex, Box, Copy } from '@elparking/components'
import { FormattedMessage, injectIntl } from 'react-intl'
import { TRADENAME } from 'commons/js/constants'

import { Control } from 'commons/js/ui/Dropdown'
import { required } from 'commons/js/util/validators'

import messages from './messages'

function mapCountryOptions (countries) {
    return countries.map((country) => ({
        country,
        value: country.iso3,
        label: country.name,
    }))
}

function renderCountryOption (option) {
    return (
        <Flex alignItems='center'>
            <Box pr='12px'>
                <img src={`/images/flag/${option.country.iso3}.svg`} />
            </Box>
            <Box>
                <Copy padding='0' size='small' fontWeight='regular'>{option.label}</Copy>
            </Box>
        </Flex>
    )
}

function renderPlaceholder () {
    return (
        <FormattedMessage
          defaultMessage='¿Dónde usarás {tradename}?'
          values={{
            tradename: TRADENAME,
          }}/>
    )
}

const CountryField = ({ intl, countries, defaultCountry = {}, ...restProps }) => (
    <Control
      key={defaultCountry.iso3}
      model='.countryIso3'
      validators={{
          required: required(),
      }}
      errorMessages={{
          required: intl.formatMessage(messages.requiredError),
      }}
      // Dropdown props
      defaultValue={defaultCountry.iso3}
      options={mapCountryOptions(countries)}
      renderSelected={renderCountryOption}
      renderOption={renderCountryOption}
      renderPlaceholder={renderPlaceholder}
      onlyDesktop
      {...restProps}
    />
)

CountryField.propTypes = {
    intl: PropTypes.object,
    countries: PropTypes.array,
    defaultCountry: PropTypes.object,
}

export default injectIntl(CountryField)
