import React from 'react'
import PropTypes from 'prop-types'

import Routes from 'commons/js/routes/routes'
import findCountryBy from './findCountryBy'
import { warn } from 'commons/js/util/logger'

function buildTermsAndConditionsPath (countries, selectedCountry) {
    const country = findCountryBy(countries, { iso3: selectedCountry })
    let path = Routes.termsAndConditionsPath()
    if (country) {
        try {
            path = Routes.termsAndConditionsPath(null, country.defaultLanguage, country.iso3.toLowerCase())
        } catch (_e) {
            warn('Country not active')
        }
    }

    return path
}

const TermsLink = ({ countries = 'es', selectedCountry, children }) => (
    <a rel='noopener noreferrer' href={buildTermsAndConditionsPath(countries, selectedCountry)} target='_blank'>
        {children}
    </a>
)

TermsLink.propTypes = {
    children: PropTypes.node.isRequired,
    countries: PropTypes.array,
    selectedCountry: PropTypes.string,
}

TermsLink.defaultProps = {
    countries: [],
}

export default TermsLink
